import type { MenuDataItem } from '@ant-design/pro-components';
import { ProLayout, PageContainer } from '@ant-design/pro-components';
import { Outlet, useNavigate, useLocation, useRouteLoaderData } from 'react-router-dom';
import { ProfileOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { Avatar, Dropdown, type MenuProps } from 'antd';
import { type ReactNode, useState } from 'react';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';

import type { SSOUserInfo } from '@/api/sso';
import { logout } from '@/api/sso';
import { goLogin, goSSO } from '@/utils/sso';
import { hasPermission } from '@/apps/supplier/constant/access';
import { useTaskParams } from '@/apps/supplier/hooks/useTaskParams';
import { ReactComponent as TitleLogo } from '@/apps/supplier/assets/title.svg';
import ErrorBoundary from '@/components/ErrorBoundary';
import IconFont from '@/components/icon-font';
import useLang from '@/hooks/useLang';

import logo from '../assets/logo.svg';

import './index.css';

export default () => {
  const { setLang, isZh } = useLang();
  const location = useLocation();
  const navigate = useNavigate();
  const userInfo = useRouteLoaderData('root') as SSOUserInfo;
  const { isPreview } = useTaskParams();
  const [collapse, setCollapse] = useState(false);

  // 导航菜单，不从 routes 文件读取，单独在此定义
  const route = {
    path: '/',
    children: [
      {
        path: '/task',
        name: <FormattedMessage id="task.title" />,
        icon: <ProfileOutlined />,
        children: [
          {
            path: '/task',
          },
          {
            path: '/audit',
          },
        ],
      },
      {
        path: '/member',
        name: <FormattedMessage id="member.title" />,
        hide: !hasPermission('canReadMember'),
        icon: <UsergroupAddOutlined />,
      },
    ].filter((item) => !item.hide),
  };

  const onLogout = async () => {
    await logout();
    goLogin();
  };

  const items: MenuProps['items'] = [
    {
      key: 'team',
      icon: <IconFont className="!text-base" type="icon-yonghuguanli" />,
      label: (
        <span onClick={goSSO}>
          <FormattedMessage id={'common.personal.center'} />
        </span>
      ),
    },
    {
      key: 'quit',
      icon: <IconFont className="!text-base" type="icon-tuichu" />,
      label: (
        <a onClick={onLogout}>
          <FormattedMessage id={'common.quit'} />
        </a>
      ),
    },
  ];

  const name = userInfo?.username || userInfo?.nickname;
  return (
    <ProLayout
      className="layout-wrapper"
      logo={logo}
      // @ts-ignore
      title={<TitleLogo className="ml-2 -mt-1" />}
      pageTitleRender={() => ''}
      breakpoint={false}
      collapsedButtonRender={false}
      collapsed={collapse}
      onCollapse={setCollapse}
      token={{
        pageContainer: {
          paddingBlockPageContainerContent: 0,
          paddingInlinePageContainerContent: 0,
        },
        sider: {
          colorMenuBackground: '#fff',
          colorTextMenu: 'var(--color-text-base)',
          colorTextMenuActive: 'var(--color-text-base)',
          colorTextMenuItemHover: 'var(--color-text-base)',
          colorTextMenuSelected: 'var(--color-text-base)',
        },
      }}
      headerTitleRender={() => <span>33</span>}
      route={!isPreview ? route : []}
      suppressSiderWhenMenuEmpty={true}
      // @ts-ignore
      ErrorBoundary={ErrorBoundary}
      location={location}
      avatarProps={{
        render: () => (
          // @ts-ignore
          <Dropdown placement="leftTop" menu={{ items }}>
            <div className={clsx('flex items-center gap-x-1 cursor-pointer', !collapse && 'ml-4')}>
              <Avatar className="bg-primary">{name[0]}</Avatar>
              {!collapse && <span className="text-color">{name}</span>}
            </div>
          </Dropdown>
        ),
      }}
      actionsRender={() => [
        <IconFont
          key="lang"
          type="icon-zhongyingwenfanyi"
          className="text-color hover:text-black"
          onClick={() => setLang(isZh ? 'en-US' : 'zh-CN')}
        />,
        <span key="1" className="text-color" onClick={() => setCollapse(!collapse)}>
          {collapse ? <IconFont type="icon-zhankai" /> : <IconFont type="icon-shouqi" />}
        </span>,
      ]}
      menuFooterRender={() => <div />}
      onMenuHeaderClick={() => navigate('/')}
      menuItemRender={(item: MenuDataItem, dom: ReactNode) => (
        <span
          onClick={() => {
            navigate(item.path || '/');
          }}
        >
          {dom}
        </span>
      )}
    >
      <PageContainer
        header={{
          breadcrumb: {},
          title: null,
        }}
      >
        <div className="min-h-screen bg-white">
          <Outlet />
        </div>
      </PageContainer>
    </ProLayout>
  );
};
